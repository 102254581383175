import { Autocomplete, Box, Button, Card, CardContent, Chip, FormControl, Grid, InputLabel, MenuItem, Select, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import CommonInput from '../utils/CommonInput';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { useNavigate, useParams } from 'react-router-dom';
import CommonSelect from '../utils/CommonSelect';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import { decryptId } from '../utils/encryptDecrypt';
import LanguageSelector from '../utils/LanguageSelector';
import { IoMdArrowBack } from 'react-icons/io';
import CloseIcon from '@mui/icons-material/Close';
import CommonSwitch from '../utils/CommonSwitch';
import InfoIcon from '@mui/icons-material/Info';
import HandIcon from '../../images/icons/handIcon';
import PATH from '../utils/path';
import { useSelector } from 'react-redux';

const CreateProduct = () => {
  const { t } = useTranslation();
  const user = useSelector((store) => store.user);
  const navigate = useNavigate();
  const params = useParams();
  let currentPage = params?.page;
  const fileInputRef = useRef(null);
  const [imageUrls, setImageUrls] = useState([]);
  const [selectImage, setSelectImage] = useState('');
  const [showTitle, setShowTitle] = useState(true);
  const [showAdditionalSetting, setShowAdditionalSettings] = useState(false);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [careInstructions, setCareInstructions] = useState([]);
  const [updateMode, setUpdateMode] = useState(false);
  const category = ['TOP', 'BOTTOM', 'DRESS', 'SHOES', 'ACCESSORIES', 'BAG', 'TEXTILE', 'YARN'];
  const priceUnit = ['rs', '$', '£', '€', '¥'];
  const weightUnit = ['gm', 'kg', 'oz'];
  const [formData, setFormData] = useState({
    files: [],
    skyQRcode: '',
    title: '',
    composition: [],
    ecommerceId: '',
    weight: '',
    weightUnit: weightUnit[0],
    collection: '',
    careInstructions: [],
    careInfo: '',
    category: '',
    colors: '',
    price: '',
    priceUnit: priceUnit[0],
    weave: '',
    isArchived: false,
  });
  const [skuId, setSkuId] = useState('');
  const [errors, setErrors] = useState({
    title: '',
    skyQRcode: '',
    ecommerceId: '',
    file: '',
  });
  const [lang, setLang] = useState('');
  const [name, setName] = useState('');
  const [percentage, setPercentage] = useState('');

  const handleAdd = () => {
    if (name && percentage) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        composition: [...(prevFormData?.composition || []), { name, percentage }],
      }));
      setName('');
      setPercentage('');
    }
  };
  const handleDelete = (index) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      composition: prevFormData.composition.filter((_, i) => i !== index),
    }));
  };
  const handleAdditionalSetting = () => {
    setShowAdditionalSettings(!showAdditionalSetting);
  };

  const validFileFormat = (files) => {
    const newErrors = { file: '' };
    let valid = true;
    let totalSize = 0;

    if (files.length === 0) {
      newErrors.file = t('no_files_selected');
      valid = false;
    } else {
      files.forEach((file) => {
        if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
          newErrors.file = t('only_image_files_PNG_JPEG_JPG_are_allowed');
          valid = false;
        } else if (file.size > 1024 * 1024) {
          newErrors.file = t('file_size_exceeds_limit');
          valid = false;
        }
        totalSize += file.size;
      });
      if (totalSize > 5 * 1024 * 1024) {
        newErrors.file = t('total_file_size_exceeds_limit');
        valid = false;
      }
    }

    return { valid, newErrors };
  };
  useEffect(() => {
    if (imageUrls) {
      setSelectImage(imageUrls[0]);
    }
  }, [imageUrls]);
  const handleFileInput = (e) => {
    const newFiles = Array.from(e.target.files);
    const allFiles = [...newFiles];
    const { valid, newErrors } = validFileFormat(allFiles);
    if (!valid) {
      setErrors(newErrors);
      return;
    }
    const imageUrlUpdate = newFiles.map((file) => URL.createObjectURL(file));
    if (updateMode) {
      setImageUrls([...imageUrls, ...imageUrlUpdate]);
      setFormData((prevFormData) => ({
        ...prevFormData,
        files: [...formData.files, ...newFiles],
      }));
    } else {
      setImageUrls([...imageUrls, ...imageUrlUpdate]);
      setFormData((prevFormData) => ({
        ...prevFormData,
        files: [...formData.files, ...newFiles],
      }));
    }
  };

  const removeImage = (index, isUploaded) => {
    if (isUploaded) {
      handleRemoveImage(imageUrls[index], index);
    } else {
      const updatedFiles = imageUrls.filter((_, i) => i !== index);
      setImageUrls(updatedFiles);
    }
  };

  const handleRemoveImage = async (imgUrl, index) => {
    // const newUrl = imgUrl.split('https://renoon-dev-public.s3.eu-west-1.amazonaws.com/' || 'https://static.renoon.com/')[1];
    let newUrl = imgUrl;
    if (imgUrl.startsWith('https://renoon-dev-public.s3.eu-west-1.amazonaws.com/')) {
      newUrl = imgUrl.split('https://renoon-dev-public.s3.eu-west-1.amazonaws.com/')[1];
    } else if (imgUrl.startsWith('https://static.renoon.com/')) {
      newUrl = imgUrl.split('https://static.renoon.com/')[1];
    }
    try {
      const response = await httpService.get(`${RestUrlsConstants.product_image_delete}/${decryptId(params.id)}?file=${newUrl}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      if (response?.data?.status) {
        // setSnackBar({ open: true, message: response?.data?.message });
        const updatedFiles = imageUrls.filter((_, i) => i !== index);
        setImageUrls(updatedFiles);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      // setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  useEffect(() => {
    const fetchInitialProductData = async () => {
      if (params?.id) {
        try {
          const response = await httpService.get(`${RestUrlsConstants.product}/${decryptId(params.id)}`, {
            headers: { Authorization: localStorage.getItem('Authorization') },
          });
          if (response?.data?.status) {
            setUpdateMode(true);
            setImageUrls(response?.data?.data?.urls);
            setSkuId(response?.data?.data?.qrId === 'undefined' || response?.data?.data?.qrId === 'null' ? '' : response?.data?.data?.qrId);
            const qrIdExists = response?.data?.data.hasOwnProperty('qrId');
            const qrIdValue = qrIdExists && response?.data?.data?.qrId && response?.data?.data?.qrId !== 'undefined' && response?.data?.data?.qrId !== 'null' ? response?.data?.data?.qrId : '';
            setFormData((prevData) => ({
              ...prevData,
              skyQRcode: qrIdValue,
              ecommerceId: response?.data?.data?.ecommerceId[0] === 'null' || response?.data?.data?.ecommerceId[0] === 'undefined' ? '' : response?.data?.data?.ecommerceId.map((ids) => ids),
              weight: response?.data?.data?.weight?.value,
              weightUnit: response?.data?.data?.weight?.unit,
              collection:
                response?.data?.data?.collectionName === 'null' || response?.data?.data?.collectionName === 'undefined' || response?.data?.data?.collectionName === null
                  ? ''
                  : response?.data?.data?.collectionName,
              careInstructions: response?.data?.data?.careInstructions,
              careInfo:
                response?.data?.data?.additionalCareInstructions === 'null' || response?.data?.data?.additionalCareInstructions === 'undefined' ? '' : response?.data?.data?.additionalCareInstructions,
              category: response?.data?.data?.category === 'undefined' || response?.data?.data?.category === 'null' ? '' : response?.data?.data?.category,
              price: response?.data?.data?.price?.value,
              priceUnit: response?.data?.data?.price?.unit,
              isArchived: response?.data?.data?.isArchived,
            }));
          }
        } catch (error) {
          console.error('Error fetching product:', error);
        }
      }
    };
    fetchInitialProductData();
  }, [params?.id]);

  useEffect(() => {
    const fetchLanguageSpecificData = async () => {
      if (params?.id) {
        try {
          const response = await httpService.get(`${RestUrlsConstants.product}/${decryptId(params.id)}`, {
            headers: { Authorization: localStorage.getItem('Authorization') },
          });
          if (response?.data?.status) {
            setFormData((prevData) => ({
              ...prevData,
              title: response?.data?.data?.title?.[lang] || response?.data?.data?.title?.value,
              composition:
                response?.data?.data?.composition?.value === 'undefined' || response?.data?.data?.composition?.value === 'null'
                  ? ''
                  : response?.data?.data?.composition?.[lang] || response?.data?.data?.composition?.value,
              colors:
                response?.data?.data?.colors?.value === 'undefined' || response?.data?.data?.colors?.value === 'null'
                  ? ''
                  : response?.data?.data?.colors?.[lang] || response?.data?.data?.colors?.value,
              weave:
                response?.data?.data?.weave?.value === 'undefined' || response?.data?.data?.weave?.value === 'null' ? '' : response?.data?.data?.weave?.[lang] || response?.data?.data?.weave?.value,
            }));
          }
        } catch (error) {
          console.error('Error fetching language-specific data:', error);
        }
      }
    };
    fetchLanguageSpecificData();
  }, [lang, params?.id]);

  const fetchCareInstructions = async () => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.product_care_instruction}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data?.status) {
        setCareInstructions(response?.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchCareInstructions();
  }, []);

  const validateForm = () => {
    let valid = true;
    const newErrors = { title: '', skyQRcode: '', ecommerceId: '' };

    if (!formData?.title?.trim()) {
      newErrors.title = t('title_is_required');
      valid = false;
    }

    const isSkyQRcodeFilled = formData.skyQRcode?.trim()?.length > 0;
    const isEcommerceIdFilled = formData.ecommerceId?.length > 0;
    // if (!updateMode) {
    if (!isSkyQRcodeFilled && !isEcommerceIdFilled) {
      newErrors.skyQRcode = t('sku_id_is_required');
      newErrors.ecommerceId = t('ecommerce_id_is_required');
      valid = false;
    }

    if (isSkyQRcodeFilled) {
      if (/[\/_]/.test(formData.skyQRcode)) {
        newErrors.skyQRcode = t('cannot_contain_slash_or_underscore');
        valid = false;
      }
    }

    if (isEcommerceIdFilled) {
      if (/[\/_]/.test(formData.ecommerceId)) {
        newErrors.ecommerceId = t('cannot_contain_slash_or_underscore');
        valid = false;
      }
    }
    // }
    setErrors(newErrors);
    return valid;
  };

  const createProduct = async () => {
    if (!validateForm()) return;
    const formDataSend = new FormData();
    if (formData?.files?.length) {
      for (let i = 0; i < formData?.files?.length; i++) {
        formDataSend.append('images', formData.files[i]);
      }
    }

    if (!updateMode) {
      let ecommerceIdArray = [];
      ecommerceIdArray = formData?.ecommerceId?.split(',');
      if (formData?.ecommerceId !== '') {
        for (let i = 0; i < ecommerceIdArray?.length; i++) {
          formDataSend.append(`ecommerceId[${i}]`, ecommerceIdArray[i]);
        }
      }
      if (formData.skyQRcode !== '') formDataSend.append('qrId', formData.skyQRcode);
    }

    if (formData.composition?.length) {
      for (let i = 0; i < formData?.composition?.length; i++) {
        formDataSend.append(`composition[${i}][name]`, formData?.composition[i]?.name);
        formDataSend.append(`composition[${i}][percentage]`, formData?.composition[i]?.percentage);
      }
    }
    if (formData.careInstructions?.length) {
      for (let i = 0; i < formData?.careInstructions?.length; i++) {
        formDataSend.append(`careInstructions[${i}]`, formData.careInstructions[i]);
      }
    }
    formDataSend.append('title', formData.title);
    if (formData.weave !== '' && formData.weave !== undefined && formData.weave !== null) formDataSend.append('weave', formData.weave);
    formDataSend.append('lang', lang);
    if (formData.collection !== '' && formData.collection !== undefined) formDataSend.append('collectionName', formData?.collection?.trim() === '' ? null : formData.collection);
    if (formData.colors !== undefined && formData.colors !== '') {
      formDataSend.append('colors', formData?.colors?.trim() === '' ? null : formData.colors);
    }

    if (formData.category !== '' && formData.category !== undefined && formData.category !== null) formDataSend.append('category', formData.category);
    if (formData.careInfo !== '' && formData.careInfo !== undefined && formData.careInfo !== null) formDataSend.append('additionalCareInstructions', formData.careInfo);
    formDataSend.append(`weight[unit]`, formData.weightUnit);
    formDataSend.append(`weight[value]`, formData.weight);
    formDataSend.append('price[value]', formData.price);
    formDataSend.append('price[unit]', formData.priceUnit);
    formDataSend.append('isArchived', formData.isArchived);

    try {
      const response = await httpService.post(`${RestUrlsConstants.product}`, formDataSend, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        setFormData({});
        setSkuId('');
        setImageUrls([]);
        navigate(`${PATH.PRODUCTENGINE}/1`);
      }
    } catch (error) {
      console.log(error);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };
  const updateProduct = async () => {
    if (!validateForm()) return;
    const formDataSend = new FormData();
    if (formData?.files?.length) {
      for (let i = 0; i < formData?.files?.length; i++) {
        formDataSend.append('images', formData.files[i]);
      }
    }
    if(formData.skyQRcode !==""){
      formDataSend.append('qrId', formData.skyQRcode);
    }

    let ecommerceIdArray = [];
    let ecommerceIdString = '';
    if (Array.isArray(formData?.ecommerceId)) {
      ecommerceIdString = formData?.ecommerceId.join(',');
    } else {
      ecommerceIdString = formData?.ecommerceId;
    }
    ecommerceIdArray = ecommerceIdString.includes(',') ? ecommerceIdString.split(',') : [ecommerceIdString];
    for (let i = 0; i < ecommerceIdArray?.length; i++) {
      formDataSend.append(`ecommerceId[${i}]`, ecommerceIdArray[i]?.trim() === '' ? null : ecommerceIdArray[i]);
    }

    if (formData.composition?.length) {
      for (let i = 0; i < formData?.composition?.length; i++) {
        formDataSend.append(`composition[${i}][name]`, formData?.composition[i]?.name);
        formDataSend.append(`composition[${i}][percentage]`, formData?.composition[i]?.percentage);
      }
    }
    if (formData.careInstructions?.length) {
      for (let i = 0; i < formData?.careInstructions?.length; i++) {
        formDataSend.append(`careInstructions[${i}]`, formData.careInstructions[i]);
      }
    }
    formDataSend.append('title', formData.title);
    formDataSend.append('weave', formData?.weave?.trim() === '' ? null : formData.weave);
    formDataSend.append('lang', lang);
    formDataSend.append('collectionName', formData?.collection?.trim() === '' ? null : formData.collection);
    if (Array.isArray(formData?.colors)) {
      formDataSend.append('colors', formData.colors.join(','));
    } else {
      formDataSend.append('colors', formData?.colors?.trim() === '' ? null : formData.colors);
    }

    if (formData.category !== '' && formData.category !== undefined && formData.category !== null) formDataSend.append('category', formData.category);
    formDataSend.append('additionalCareInstructions', formData?.careInfo?.trim() === '' ? null : formData.careInfo);
    formDataSend.append(`weight[unit]`, formData.weightUnit);
    formDataSend.append(`weight[value]`, formData.weight);
    formDataSend.append('price[value]', formData.price);
    formDataSend.append('price[unit]', formData.priceUnit);
    formDataSend.append('isArchived', formData.isArchived);
    try {
      const response = await httpService.patch(`${RestUrlsConstants.product}/${decryptId(params.id)}`, formDataSend, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'multipart/form-data',
        },
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        setFormData({});
        setSkuId('');
        setImageUrls([]);
        navigate(`${PATH.PRODUCTENGINE}/${currentPage}`);
      }
    } catch (error) {
      console.log(error);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };
  const handleFormStatus = () => {
    setFormData({ ...formData, isArchived: !formData.isArchived });
  };
  const submitProduct = () => {
    if (updateMode) {
      updateProduct();
    } else {
      createProduct();
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Button
            startIcon={<IoMdArrowBack />}
            onClick={() => navigate(`${PATH.PRODUCTENGINE}/${currentPage}`)}
            sx={{ bgcolor: 'none', ':hover': { bgcolor: 'none' }, color: '#000', borderRadius: 5, textTransform: 'capitalize', px: 2 }}
          >
            {t('back')}
          </Button>
        </Grid>
        <Grid item xs={12} sm={12} md={8}>
          <Card>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'end' }}>
                <LanguageSelector setLang={setLang} selectLang={lang} />
              </Box>
              <Box mt={2}>
                <CommonInput
                  label={
                    <span>
                      {t('product_title') + '*'}
                      <Tooltip title={t('product_title')} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  placeholder={t('product_title')}
                  value={formData.title}
                  type="text"
                  variant="standard"
                  autoComplete={false}
                  handleChange={(e) => {
                    setFormData({ ...formData, title: e.target.value });
                  }}
                  error={!!errors.title}
                  helperText={errors.title}
                />
                <CommonInput
                  label={
                    <span>
                      {t('enter_sku') + '*'}
                      <Tooltip title={t('sku_id')} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  placeholder={t('mandatory_for_qr_generation')}
                  value={formData.skyQRcode}
                  type="text"
                  variant="standard"
                  disabled={updateMode && skuId}
                  autoComplete={false}
                  handleChange={(e) => {
                    setFormData({ ...formData, skyQRcode: e.target.value });
                  }}
                  error={!!errors.skyQRcode}
                  helperText={errors.skyQRcode}
                />
                <CommonInput
                  label={
                    <span>
                      {t('e_commerce_id') + '*'}
                      <Tooltip title={t('e_commerce_id')} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  placeholder={t('ecommerce Id')}
                  value={formData.ecommerceId}
                  type="text"
                  variant="standard"
                  autoComplete={false}
                  handleChange={(e) => {
                    setFormData({ ...formData, ecommerceId: e.target.value });
                  }}
                  error={!!errors.ecommerceId}
                  helperText={errors.ecommerceId}
                />
                <CommonInput
                  label={
                    <span>
                      {t('insert_collection')}
                      <Tooltip title={t('collection_name')} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  placeholder={t('enter_collection_name')}
                  value={formData.collection}
                  type="text"
                  variant="standard"
                  autoComplete={false}
                  handleChange={(e) => setFormData({ ...formData, collection: e.target.value })}
                />
                <CommonInput
                  label={
                    <span>
                      {t('weave')}
                      <Tooltip title={t('weave')} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </span>
                  }
                  placeholder={t('weave')}
                  value={formData.weave}
                  type="text"
                  variant="standard"
                  autoComplete={false}
                  handleChange={(e) => setFormData({ ...formData, weave: e.target.value })}
                />
                <InputLabel sx={{ mt: 4, color: '#000', fontWeight: '600', fontSize: '14px' }}>
                  {t('composition')}{' '}
                  <Tooltip title={t('composition')} placement="top-start">
                    <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                  </Tooltip>
                </InputLabel>
                <Box display="flex" flexWrap="wrap" sx={{ borderBottom: '1px solid gray', pt: 1 }} gap={1}>
                  {formData?.composition?.map((item, index) => (
                    <Chip sx={{ mb: '2px' }} key={index} label={`${item?.name} - ${item?.percentage}%`} onDelete={() => handleDelete(index)} />
                  ))}
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', height: '50px' }} gap={2} mb={2}>
                  <CommonInput placeholder={t('material')} value={name} type="text" variant="standard" autoComplete={false} handleChange={(e) => setName(e.target.value)} />
                  <CommonInput placeholder={t('percentage')} value={percentage} type="text" variant="standard" autoComplete={false} handleChange={(e) => setPercentage(e.target.value)} />
                  <Button sx={{ mt: 4, background: '#0e1090', borderRadius: '20px', ':hover': { background: '#0e1090' } }} size="small" variant="contained" onClick={handleAdd}>
                    {t('add')}
                  </Button>
                </Box>
                <Box sx={{ width: '100px' }}>
                  <InputLabel sx={{ mt: 4, color: '#000', fontWeight: '600', fontSize: '14px' }}>
                    {t('archive')}
                    <Tooltip title={t('archive')} placement="top-start">
                      <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                    </Tooltip>
                  </InputLabel>
                  <CommonSwitch checkValue={formData.isArchived} onChange={() => handleFormStatus()} />
                </Box>
                <Typography onClick={() => handleAdditionalSetting()} mt={4} sx={{ color: '#0e1090', fontSize: '12px', fontWeight: 'bold', cursor: 'pointer', width: '200px' }}>
                  {t('additional_information')} {showAdditionalSetting ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </Typography>
                {showAdditionalSetting && (
                  <Box>
                    <InputLabel sx={{ mt: 4, color: '#000', fontWeight: '600', fontSize: '14px' }}>
                      {t('care_instruction')}
                      <Tooltip title={t('care_instruction')} placement="top-start">
                        <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                      </Tooltip>
                    </InputLabel>
                    <Autocomplete
                      multiple
                      id="care-instructions"
                      options={careInstructions}
                      freeSolo
                      getOptionLabel={(option) => option?.value || option}
                      value={formData.careInstructions?.map((id) => careInstructions?.find((item) => item?.id === id))}
                      onChange={(event, newValue) => {
                        const updatedValue = newValue
                          ?.map((option) => {
                            if (typeof option === 'string') {
                              return null;
                            }
                            return option.id;
                          })
                          .filter((id) => id !== null);
                        setFormData({ ...formData, careInstructions: updatedValue });
                      }}
                      renderTags={(value, getTagProps) => value?.map((option, index) => <Chip variant="contained" label={option?.value} {...getTagProps({ index })} />)}
                      renderInput={(params) => <TextField {...params} variant="standard" />}
                    />
                    <CommonInput
                      label={
                        <span>
                          {t('additional_care_info')}
                          <Tooltip title={t('additional_care_info')} placement="top-start">
                            <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                          </Tooltip>
                        </span>
                      }
                      placeholder={t('enter_detail_here')}
                      value={formData?.careInfo}
                      type="text"
                      variant="standard"
                      autoComplete={false}
                      handleChange={(e) => setFormData({ ...formData, careInfo: e.target.value })}
                    />
                    <Grid container>
                      <Grid item xs={10}>
                        <CommonInput
                          label={
                            <span>
                              {t('weight')}
                              <Tooltip title={t('weight')} placement="top-start">
                                <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                              </Tooltip>
                            </span>
                          }
                          placeholder={t('enter_detail_here')}
                          value={formData.weight}
                          type="number"
                          variant="standard"
                          autoComplete={false}
                          handleChange={(e) => setFormData({ ...formData, weight: e.target.value })}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl size="small" sx={{ marginTop: '36px' }} fullWidth variant="filled">
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            fullWidth
                            sx={{ border: 'none' }}
                            size="small"
                            value={formData.weightUnit}
                            onChange={(e) => setFormData({ ...formData, weightUnit: e.target.value })}
                            autoWidth
                          >
                            {weightUnit?.map((unit) => (
                              <MenuItem value={unit}>{unit}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                    <CommonInput
                      label={
                        <span>
                          {t('colors')}
                          <Tooltip title={t('colors')} placement="top-start">
                            <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                          </Tooltip>
                        </span>
                      }
                      placeholder={t('colors')}
                      value={formData?.colors}
                      type="text"
                      variant="standard"
                      autoComplete={false}
                      handleChange={(e) => setFormData({ ...formData, colors: e.target.value })}
                    />
                    <CommonSelect
                      label={
                        <span>
                          {t('choose_category')}
                          <Tooltip title={t('category')} placement="top-start">
                            <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                          </Tooltip>
                        </span>
                      }
                      options={category}
                      value={formData.category}
                      handleChange={(e) => setFormData({ ...formData, category: e.target.value })}
                    />
                    <Grid container>
                      <Grid item xs={10}>
                        <CommonInput
                          label={
                            <span>
                              {t('price')}
                              <Tooltip title={t('price')} placement="top-start">
                                <InfoIcon style={{ marginLeft: 4, fontSize: '18px' }} />
                              </Tooltip>
                            </span>
                          }
                          placeholder={t('price')}
                          value={formData.price}
                          type="number"
                          variant="standard"
                          autoComplete={false}
                          handleChange={(e) => setFormData({ ...formData, price: e.target.value })}
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <FormControl size="small" sx={{ marginTop: '36px' }} fullWidth variant="filled">
                          <Select
                            labelId="demo-simple-select-filled-label"
                            id="demo-simple-select-filled"
                            fullWidth
                            sx={{ border: 'none' }}
                            size="small"
                            value={formData.priceUnit}
                            onChange={(e) => setFormData({ ...formData, priceUnit: e.target.value })}
                            autoWidth
                          >
                            {priceUnit?.map((unit) => (
                              <MenuItem value={unit}>{unit}</MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>
                    </Grid>
                  </Box>
                )}
              </Box>
              <Box sx={{ mt: 2 }}>
                <Button
                  startIcon={<HandIcon color="#fff" />}
                  variant="contained"
                  size="small"
                  sx={{ borderRadius: '20px', background: '#0e1090', ':hover': { background: '#0e1090' }, px: 2 }}
                  onClick={submitProduct}
                >
                  {t('save')}
                </Button>
              </Box>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={12} md={4}>
          <Card>
            <CardContent>
              <Typography sx={{ fontSize: '14px', fontWeight: 'bold' }}>{t('attach_image')}</Typography>
              <Box mb={2}>
                {imageUrls?.length > 0 && (
                  <Box sx={{ borderRadius: '10px' }}>
                    <img src={selectImage} alt="" style={{ borderRadius: '10px' }} width="100%" />
                  </Box>
                )}
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px' }}>
                  {imageUrls?.map((url, index) => (
                    <Box
                      key={index}
                      sx={{
                        height: '80px',
                        width: '80px',
                        borderRadius: '8px',
                        mt: 2,
                        position: 'relative',
                      }}
                    >
                      <img
                        src={url}
                        alt=""
                        height="80px"
                        width="80px"
                        style={{ border: selectImage === url ? '2px solid red' : 'none', borderRadius: '8px' }}
                        onClick={() => {
                          setSelectImage(url);
                          setShowTitle(index === 0);
                        }}
                      />
                      {selectImage === url && (
                        <Typography
                          sx={{
                            lineHeight: '80px',
                            width: '80px',
                            textAlign: 'center',
                            fontSize: '12px',
                            fontWeight: 'bold',
                            color: '#fff',
                            background: 'rgba(0,0,0,.5)',
                            borderRadius: '8px',
                            position: 'absolute',
                            zIndex: 100,
                            top: 0,
                            left: 0,
                          }}
                        >
                          {showTitle && 'Default'}
                        </Typography>
                      )}
                      <CloseIcon
                        onClick={(e) => {
                          removeImage(index, url.startsWith('https'));
                        }}
                        sx={{
                          cursor: 'pointer',
                          fontSize: '16px',
                          position: 'absolute',
                          top: '-5px',
                          right: '-5px',
                          color: 'red',
                          zIndex: 200,
                          background: '#fff',
                          borderRadius: '5px',
                          border: '.5px solid gray',
                        }}
                      />
                    </Box>
                  ))}
                </Box>
              </Box>
              <input ref={fileInputRef} multiple type="file" accept="image/png, image/jpeg, image/png" hidden onChange={handleFileInput} />
              {errors.file && (
                <Typography variant="body2" color={'error'}>
                  {errors.file}
                </Typography>
              )}
              <Button
                sx={{
                  height: '80px',
                  width: '80px',
                  color: '#fff',
                  background: '#000',
                  ':hover': { background: '#222' },
                }}
                variant="contained"
                onClick={() => {
                  fileInputRef.current.click();
                  setErrors({ file: '' });
                }}
              >
                <AddIcon sx={{ color: '#fff' }} />
              </Button>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
};

export default CreateProduct;
