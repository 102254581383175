import {
  Box,
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
  Chip,
  DialogContentText,
  IconButton,
  Snackbar,
  Typography,
  InputAdornment,
  TextField,
  Pagination,
} from '@mui/material';
import CommonSwitch from '../utils/CommonSwitch';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useEffect, useState } from 'react';
import moment from 'moment';
import CommonDialog from '../utils/CommonDialog';
import { CommonButton } from '../utils/CommonButton';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuestionForm } from '../../features/questionsFormSlice';
import ArticleIcon from '@mui/icons-material/Article';
import { fetchCertificate, searchCertificate } from '../../features/certificateProofPointSlice';
import Loader from '../utils/loader';
import SearchIcon from '@mui/icons-material/Search';
import { CategoryStatus, CertificateStatus } from '../../constants/common.constants';
import { fetchGoalsCertificates } from '../../features/certificateSlice';

const CertificateLibrary = (props) => {
  const { t } = useTranslation();
  const { openDialog, closeDialog, questionId, formId } = props;
  const [certificateId, setCertificateId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const user = useSelector((store) => store.user);
  const { certificate, loading } = useSelector((state) => state.certificate);
  const [page, setPage] = useState(1);
  const userId = user?.id;
  const itemPerPage = 10;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchCertificate({ page, itemPerPage, userId }));
  }, [dispatch, page, userId]);

  const handleClick = (id) => {
    if (certificateId === id) {
      setCertificateId('');
    } else {
      setCertificateId(id);
    }
  };

  const handleCloseDialog = () => {
    closeDialog();
    setCertificateId('');
  };

  const handleSubmit = async () => {
    if (certificateId) {
      const payload = {
        certificateId: certificateId,
      };
      try {
        const response = await httpService.post(`${RestUrlsConstants.formQueUrl}/${questionId}`, payload, {
          headers: {
            Authorization: localStorage.getItem('Authorization'),
            'Content-Type': 'multipart/form-data',
          },
        });
        if (response?.data?.status) {
          if(formId){
            dispatch(fetchQuestionForm(formId));
          }else{
            dispatch(fetchGoalsCertificates())
          }
          handleCloseDialog();
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  const isSelected = (id) => certificateId === id;

  const totalPages = Math.ceil(certificate?.totalRecords / itemPerPage);
  const handleChange = (event, value) => {
    setPage(value);
  };
  const handleSearchChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    if (term.trim() === '') {
      dispatch(fetchCertificate({ page, itemPerPage, userId }));
    } else {
      dispatch(searchCertificate({ term, userId }));
    }
  };

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };
  function getFileExtension(url) {
    const extensionRegex = /\.([a-zA-Z0-9]+)(?=\?|$)/;
    const match = extensionRegex.exec(url);
    if (match) {
      const extension = match[1].toLowerCase();
      return extension === 'pdf' || extension === 'doc' || extension === 'docx' || extension === 'csv' || extension === 'ppt' || extension === 'pptx' || extension === 'txt' || extension === 'xlsx'
        ? extension
        : '';
    }
    return '';
  }

  
  return (
    <>
      <CommonDialog mb={4} heading={t('pick_certificate')} open={openDialog} handleClose={handleCloseDialog}>
        <DialogContentText>
          <Box mt={2}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              placeholder={t('search')}
              value={searchTerm}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: '20px',
                },
              }}
              onChange={handleSearchChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <SearchIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Box>
            <Card sx={{ borderRadius: '20px', boxShadow: 'none' }}>
              <CardContent>
                <Box>
                  <TableContainer>
                    <Table sx={{ cursor: 'pointer' }}>
                      <TableHead>
                        <TableRow>
                          <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}></TableCell>
                          <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('preview')}</TableCell>
                          <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('name')}</TableCell>
                          <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('date_of_emission')}</TableCell>
                          <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('expiration_date')}</TableCell>
                          <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>
                            {t('alerts')} <br /> <span style={{ fontSize: '10px', color: 'gray' }}>{t('thirty_days_before_expiration_date')}</span>
                          </TableCell>
                          <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>
                            {t('status')} <br /> <span style={{ fontSize: '10px', color: 'gray' }}>{t('form_issuing_today')}</span>
                          </TableCell>
                          <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('verified_date')}</TableCell>
                          <TableCell sx={{ fontWeight: '700', fontSize: '12.5px' }}>{t('visibility')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {loading ? (
                          <TableRow>
                            <TableCell colSpan={9}>
                              <Loader />
                            </TableCell>
                          </TableRow>
                        ) : (
                          <>
                            {certificate?.currentRecords < 1 ? (
                              <TableRow>
                                <TableCell colSpan={9}>
                                  <Typography py={2} textAlign={'center'}>
                                    {t('no_certificate_found')}
                                  </Typography>
                                </TableCell>
                              </TableRow>
                            ) : (
                              <>
                                {certificate?.certificates?.map((val, index) => {
                                  const isItemSelected = isSelected(val?.id);
                                  return (
                                    <TableRow
                                      sx={{ ':hover': { background: '#f5f5f5' } }}
                                      key={index}
                                      role="checkbox"
                                      aria-checked={isItemSelected}
                                      selected={isItemSelected}
                                      onClick={() => handleClick(val?.id)}
                                    >
                                      <TableCell padding="checkbox" sx={{ border: 'none' }}>
                                        <Checkbox checked={isItemSelected} />
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: '700', fontSize: '12px', border: 'none' }}>
                                        <>
                                          {(val?.urls[0]?.includes('.pdf') ||
                                            val?.urls[0]?.includes('.doc') ||
                                            val?.urls[0]?.includes('.docx') ||
                                            val?.urls[0]?.includes('.csv') ||
                                            val?.urls[0]?.includes('.txt') ||
                                            val?.urls[0]?.includes('.xlsx') ||
                                            val?.urls[0]?.includes('.ppt') ||
                                            val?.urls[0]?.includes('.pptx')) && (
                                            <Box
                                              sx={{
                                                height: '90px',
                                                width: '80px',
                                                border: '2px solid lightgray',
                                                bgcolor: '#fff',
                                                textAlign: 'justify',
                                                display: 'flex',
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                position: 'relative',
                                              }}
                                            >
                                              <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                                                <ArticleIcon style={{ fontSize: '40px' }} />
                                                <span style={{ fontSize: '14px', color: 'gray', textTransform: 'uppercase' }}>{getFileExtension(val?.urls[0])}</span>
                                              </Box>
                                            </Box>
                                          )}
                                          {(val?.urls[0]?.includes('.png') || val?.urls[0]?.includes('.jpeg') || val?.urls[0]?.includes('.jpg')) && (
                                            <img src={val?.urls} alt="" height={'90px'} width={'80px'} style={{ border: '2px solid lightgray ' }} />
                                          )}
                                        </>
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: '700', fontSize: '12px', border: 'none', textTransform: 'uppercase' }}>{val?.name}</TableCell>
                                      <TableCell sx={{ fontWeight: '700', fontSize: '12px', border: 'none' }}>{val?.emissionDate ? moment(val?.emissionDate).format('DD/MM/YYYY') : ''}</TableCell>
                                      <TableCell sx={{ fontWeight: '700', fontSize: '12px', border: 'none' }}>{val?.expirationDate ? moment(val?.expirationDate).format('DD/MM/YYYY') : ''}</TableCell>
                                      <TableCell sx={{ border: 'none' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: '700', fontSize: '12px' }}>
                                          <CommonSwitch checkValue={val?.isExpiredNotificationEnabled} /> {val?.isExpiredNotificationEnabled ? 'ON' : 'OFF'}
                                        </Box>
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: '700', fontSize: '12px', border: 'none' }}>
                                        <Chip
                                          size="small"
                                          label={
                                            <div>
                                              {val?.verificationStatus === CertificateStatus.valid && <CheckCircleIcon sx={{ fontSize: '16px', color: '#fff', mr: 1 }} />}
                                              {val?.verificationStatus}
                                            </div>
                                          }
                                          sx={{
                                            fontSize: '10px',
                                            bgcolor: val?.verificationStatus === CertificateStatus.valid ? '#28a745' : val?.verificationStatus === CategoryStatus.pending ? '#0e1090' : '#c82333',
                                            color: '#fff',
                                          }}
                                        />
                                      </TableCell>
                                      <TableCell sx={{ fontWeight: '700', fontSize: '12px', border: 'none' }}>
                                        {' '}
                                        {val?.verificationStatus === 'VALID' ? moment(val?.verifiedOn).format('DD/MM/YYYY') : 'Not Verified'}
                                      </TableCell>
                                      <TableCell sx={{ border: 'none' }}>
                                        <Box sx={{ display: 'flex', alignItems: 'center', fontWeight: '700', fontSize: '12px' }}>
                                          <CommonSwitch checkValue={val?.public} />
                                          {val?.public ? 'Public' : 'Private'}
                                        </Box>
                                      </TableCell>
                                    </TableRow>
                                  );
                                })}
                              </>
                            )}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </TableContainer>
                  <Box mt={1} sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box>{certificateId && <CommonButton buttonName={'Submit'} handleClick={handleSubmit} />}</Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: '5px' }}>
                      {certificate?.totalRecords > 0 && (
                        <Pagination
                          sx={{
                            '& .MuiPaginationItem-root': {
                              color: 'gray',
                            },
                            '& .MuiPaginationItem-root.Mui-selected': {
                              backgroundColor: '#EC895A',
                              color: '#fff',
                            },
                          }}
                          count={totalPages}
                          page={page}
                          onChange={handleChange}
                        />
                      )}
                    </Box>
                  </Box>
                </Box>
              </CardContent>
            </Card>
          </Box>
        </DialogContentText>
      </CommonDialog>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
};

export default CertificateLibrary;
