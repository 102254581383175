import { Box, Button, Card, CardContent, CardMedia, Chip, Grid, Snackbar, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { IoMdArrowBack } from 'react-icons/io';
import { useTranslation } from 'react-i18next';
import { CategoryStatus } from '../../constants/common.constants';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useEffect, useState } from 'react';
import { fetchClaimCategoryForm } from '../../features/categoryClaimFormSlice';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import { useNavigate } from 'react-router-dom';
import PATH from '../utils/path';
import { encryptId } from '../utils/encryptDecrypt';
import Loader from '../utils/loader';

export const ClientQueForm = () => {
  const user = useSelector((store) => store.user);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const dispatch = useDispatch();

  const { claimCategoryForm, loading } = useSelector((state) => state.categoryClaimForm);
  useEffect(() => {
    dispatch(fetchClaimCategoryForm());
  }, [dispatch]);

  
  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  const handleClientFormStatus = async () => {
    const payload = {
      status: CategoryStatus.submitted,
    };
    try {
      const response = await httpService.put(`${RestUrlsConstants.brandUrl}${user?.brand?.id}`, payload, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        dispatch(fetchClaimCategoryForm());
      }
    } catch (error) {
      console.error('Error fetching form:', error);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };
  const [expandedItems, setExpandedItems] = useState({});

  // Toggle expanded state for a given item
  const handleToggleExpand = (index, section) => {
    setExpandedItems((prev) => ({
      ...prev,
      [`${index}-${section}`]: !prev[`${index}-${section}`],
    }));
  };
  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <Button
            startIcon={<IoMdArrowBack />}
            onClick={() => navigate(PATH.HOME)}
            sx={{ bgcolor: 'none', ':hover': { bgcolor: 'none' }, color: '#000', borderRadius: 5, textTransform: 'capitalize', px: 2,mb:3  }}
          >
            {t('back')}
          </Button>
          <Card sx={{ boxShadow: 'none', borderRadius: '10px' }}>
            <CardContent>
              <Grid container spacing={4}>
                <Grid item xs={12}>
                  <Box>
                    <Typography sx={{ fontWeight: '700', fontSize: '16px' }}>{t('fill_the_forms')}</Typography>
                    <Typography sx={{ fontSize: '12px', color: 'gray' }}>{t('the_forms_are_saved_automatically_You_can_submit_the_forms_once_all_the_questions_are_answered')}</Typography>
                  </Box>
                </Grid>
                {loading ? (
                  <Grid item xs={12}>
                    <Loader />
                  </Grid>
                ) : (
                  <>
                    {claimCategoryForm?.forms?.map((val, index) => (
                      <Grid item xs={12} sm={12} md={4}>
                        <Grid container key={index} sx={{ borderRadius: '20px', boxShadow: '1px 2px 2px #cccccc' }}>
                          <Grid item xs={12}>
                            <CardMedia component="img" sx={{ height: '200px', width: '100%', borderTopLeftRadius: '20px', borderTopRightRadius: '20px' }} image={val.imageUrl} title={val.title} />
                          </Grid>
                          <Grid item xs={12} p={2}>
                            <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }} variant="h6">
                              {val.title}
                            </Typography>
                            <Typography sx={{ fontSize: '12px' }} color="text.secondary">
                              {expandedItems[`${index}-description`] ? val.shortDescription : `${val.shortDescription.substring(0, 50)}...`}
                            </Typography>
                            <Button onClick={() => handleToggleExpand(index, 'description')} sx={{ textTransform: 'none', padding: 0, fontSize: '12px' }}>
                              {expandedItems[`${index}-description`] ? t('read_less') : t('read_more')}
                            </Button>
                          </Grid>
                          <Grid className="example" item xs={12} p={2} sx={{ display: 'flex', flexWrap: 'wrap', gap: '10px', height: '100px', overflowY: 'auto' }}>
                            {val?.claims?.map((claim) => (
                              <Chip sx={{ bgcolor: '#0e1090', color: '#fff', fontSize: '12px' }} label={claim} />
                            ))}
                          </Grid>
                          <Grid item xs={12} p={2} sx={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Box>
                              <Typography sx={{ fontWeight: 'bold', fontSize: '16px' }}>
                                {val.totalAnswers}/{val.totalQuestions}
                              </Typography>
                              <Typography sx={{ fontWeight: 'bold', fontSize: '14px', color: 'gray' }}>{t('questions_answered')}</Typography>
                            </Box>
                            <Box>
                              <Button
                                size="small"
                                variant="contained"
                                sx={{
                                  borderRadius: '20px',
                                  background: '#101010',
                                  ':hover': { background: '#101010' },
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => {
                                  navigate(`${PATH.QUESTIONFORM}/${encryptId(val?.id)}`);
                                }}
                              >
                                {t('continue')}
                                <NavigateNextIcon />
                              </Button>
                            </Box>
                          </Grid>
                        </Grid>
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            </CardContent>
          </Card>
          <Card sx={{ borderRadius: '10px', boxShadow: 'none', mt: 3 }}>
            <CardContent>
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography sx={{ fontWeight: '700', fontSize: '16px' }}>{t('submit_for_approval')}</Typography>
                <Box sx={{ mt: 2 }}>
                  <Button
                    onClick={handleClientFormStatus}
                    sx={{ fontWeight: 'bold', textTransform: 'none', color: '#fff', borderRadius: '10px', background: '#0e1090', ':hover': { background: '#0e1090' } }}
                  >
                    {t('submit')}
                    <NavigateNextIcon />
                  </Button>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
};
