import { Snackbar } from '@mui/material';
import SelectedClaims from './selectedClaims';
import { useEffect, useState } from 'react';
import httpService from '../../service/http.service';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import { useDispatch, useSelector } from 'react-redux';
import { CategoryStatus } from '../../constants/common.constants';
import ReviewClaim from './ReviewClaim';
import { fetchGoalsCertificates } from '../../features/certificateSlice';

const ClaimManagement = () => {
  const [openClaimForm, setOpenClaimForm] = useState(false);
  const [certificate, setCertificate] = useState([]);
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [claimForm, setClaimForm] = useState([]);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [claimQuestions, setClaimQuestions] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const [categoryFormName, setCategoryFormName] = useState('');
  const [claimOptions, setClaimOptions] = useState([]);
  const [showUserClaimDetail, setShowUserClaimDetail] = useState(false);
  const [claimDetail, setClaimDetail] = useState({});
  const [updateClaim, setUpdateClaim] = useState(false);
  const [openUpdateClaimForm, setOpenUpdateClaimForm] = useState(false);
  const [categoryClaim, setCategoryClaim] = useState({});
  const [updatingItemId, setUpdatingItemId] = useState(null);
  const [selectCategoryId, setSelectCategoryId] = useState('');
  const user = useSelector((store) => store.user);
  const { certificateGoals } = useSelector((state) => state.certificatesGoals);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchGoalsCertificates());
  }, [dispatch]);

  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };

  useEffect(() => {
    setCertificate(certificateGoals?.certifications);
    setGoals(certificateGoals?.goals);
  }, [certificateGoals]);

  const handleClientFormStatus = async () => {
    const payload = {
      status: CategoryStatus.submitted,
    };
    try {
      const response = await httpService.put(`${RestUrlsConstants.brandUrl}${user?.brand?.id}`, payload, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        fetchClaim();
      }
    } catch (error) {
      console.error('Error fetching form:', error);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const fetchClaim = async (id) => {
    setLoading(true);
    setUpdatingItemId(id)
    try {
      const response = await httpService.get(`${RestUrlsConstants.categoryUrl}?mode=${'SELECTION'}`, { headers: { Authorization: localStorage.getItem('Authorization') } });
      setData(response?.data?.data?.categories);
      setClaimForm(response?.data?.data?.forms);
    } catch (error) {
      console.error('Error fetching claims:', error);
    } finally {
      setLoading(false);
      setUpdatingItemId(null);
    }
  };
  useEffect(() => {
    const filteredItem = data.find((item) => item.status === CategoryStatus.submitted || item.status === CategoryStatus.reviewed);
    if (filteredItem) {
      setCategoryId(filteredItem.id);
      setCategoryFormName(filteredItem.name);
    }
  }, [data, openClaimForm]);

  const fetchClaimQuestionForm = async (id) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.categoryUrl}${id}`, {
        headers: { Authorization: localStorage.getItem('Authorization') },
      });
      setClaimQuestions(response?.data?.data?.questions);
      setClaimOptions(response?.data?.data?.claimsList);
    } catch (error) {
      console.error('Error fetching form:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchClaimData = async (claimId) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.claimUrl}${claimId}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      if (response?.data?.status) {
        setClaimDetail(response?.data?.data);
        setShowUserClaimDetail(true);
      }
    } catch (error) {
      console.error('Error fetching claim:', error);
    }
  };
  const fetchUpdateClaimData = async (claimId) => {
    try {
      const response = await httpService.get(`${RestUrlsConstants.claimUrl}${claimId}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      if (response?.data?.status) {
        const fetchData=await response.data.data
        setCategoryClaim(fetchData);
      }
    } catch (error) {
      console.error('Error fetching claim:', error);
    }
  };
 
  const handleUpdateClaim=(catId, claimId)=>{
    fetchUpdateClaimData(claimId)
    setSelectCategoryId(catId);
    setUpdateClaim(true);
    setOpenUpdateClaimForm(true);
  }

  return (
    <>
      {!openClaimForm && !updateClaim ? (
        <SelectedClaims
          setLoading={setLoading}
          loading={loading}
          fetchClaim={fetchClaim}
          data={data}
          claimForm={claimForm}
          handleClientFormStatus={handleClientFormStatus}
          setCategoryFormName={setCategoryFormName}
          showUserClaimDetail={showUserClaimDetail}
          setShowUserClaimDetail={setShowUserClaimDetail}
          claimDetail={claimDetail}
          setClaimDetail={setClaimDetail}
          fetchClaimData={fetchClaimData}
          setOpenClaimForm={setOpenClaimForm}
          goals={goals}
          handleUpdateClaim={handleUpdateClaim}
          fetchClaimQuestionForm={fetchClaimQuestionForm}
          updatingItemId={updatingItemId}
        />
      ) : (
        <ReviewClaim
          openQuestion={openClaimForm}
          certificate={certificate}
          categoryFormName={categoryFormName}
          setOpenQuestionForm={setOpenClaimForm}
          questionForm={claimQuestions}
          fetchClaim={fetchClaim}
          data={data}
          loading={loading}
          setLoading={setLoading}
          setCategoryId={setCategoryId}
          categoryId={categoryId}
          setCategoryFormName={setCategoryFormName}
          claimOptions={claimOptions}
          showUserClaimDetail={showUserClaimDetail}
          fetchClaimQuestionForm={fetchClaimQuestionForm}
          updateClaim={updateClaim}
          setUpdateClaim={setUpdateClaim}
          setOpenUpdateClaimForm={setOpenUpdateClaimForm}
          openUpdateClaimForm={openUpdateClaimForm}
          categoryClaim={categoryClaim}
          setCategoryClaim={setCategoryClaim}
          selectCategoryId={selectCategoryId}
          setSelectCategoryId={setSelectCategoryId}
          handleUpdateClaim={handleUpdateClaim}
          claimDetail={claimDetail}
        />
      )}

      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
    </>
  );
};

export default ClaimManagement;
