import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Box, Button, Card, CardContent, Checkbox, Chip, Grid, IconButton, InputAdornment, Pagination, TextField, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useState } from 'react';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import user from '../../images/user.png';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import CircleIcon from '@mui/icons-material/Circle';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import httpService from '../../service/http.service';
import { useDispatch } from 'react-redux';
import { decryptId, encryptId } from '../utils/encryptDecrypt';
import PATH from '../utils/path';
import { useNavigate, useParams } from 'react-router-dom';
import FilterSupplier from '../utils/FilterSupplier';
import { fetchSupplierWithSearch } from '../../features/supplierSlice';
import { fetchChainById } from '../../features/ChainByIdSlice';

export default function SelectSupplier(props) {
  const { openSelectSupplier, supplier, closeSelectSupplier, setOpenSelectSupplier, selectedSupplyChain, productId, selectedNodeId, page, setPage, pageCount, itemsPerPage } = props;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [searchTerm, setSearchTerm] = useState('');
  const [supplyChainData, setSupplyChainData] = useState({
    supplier: [],
  });
  const currentPageData = supplier?.suppliers?.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  const params = useParams();
  const dispatch = useDispatch();
  const handleChange = (event, value) => {
    setPage(value);
  };
  const toggleSupplierSelection = (selectedSupplier) => {
    setSupplyChainData((prevData) => {
      const isSelected = prevData.supplier.some((supplier) => supplier.supplierId === selectedSupplier?.id);

      if (isSelected) {
        return {
          ...prevData,
          supplier: prevData.supplier.filter((supplier) => supplier.supplierId !== selectedSupplier?.id),
        };
      } else {
        return {
          ...prevData,
          supplier: [
            ...prevData.supplier,
            {
              supplierId: selectedSupplier?.id,
              vanityName: selectedSupplier?.vanityName?.value === 'undefined' ? '' : selectedSupplier?.vanityName?.value,
              name: selectedSupplier?.name,
              city: selectedSupplier?.address?.city,
              urls:selectedSupplier?.urls ? selectedSupplier?.urls[0]?.url:'',
              country: selectedSupplier?.address?.country,
            },
          ],
        };
      }
    });
  };

  function addSuppliers(selectedChin, selectedNodeId, productId, suppliers) {
    if (selectedChin._id === selectedNodeId) {
      let newSuppliers = [...selectedChin.suppliers, ...suppliers];
      selectedChin.suppliers = newSuppliers;
      return selectedChin;
    } else if (selectedChin.suppliers?.length) {
      selectedChin.suppliers.map((e) => {
        if (e?.products?.length) {
          e.products?.map((pro) => {
            return addSuppliers(pro, selectedNodeId, productId, supplyChainData?.supplier);
          });
        }
        return e;
      });
    }
  }
  const handleCreateSupplyChain = async () => {
    try {
      let newSelectedchain = JSON.parse(JSON.stringify(selectedSupplyChain));
      if (newSelectedchain.suppliers?.length && productId) {
        newSelectedchain.suppliers.map((e) => {
          if (e.products?.length) {
            e.products.map((pro) => {
              return addSuppliers(pro, selectedNodeId, productId, supplyChainData?.supplier);
            });
          }
          return e;
        });
      } else {
        newSelectedchain.suppliers = [...selectedSupplyChain.suppliers, ...supplyChainData?.supplier];
      }
      delete newSelectedchain.description;
      const payload = {
        ...newSelectedchain,
      };
      const response = await httpService.put(`${RestUrlsConstants.supplyChain}/${selectedSupplyChain?.id}`, payload, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
          'Content-Type': 'application/json',
        },
      });
      if (response?.data?.status) {
        setSupplyChainData({
          supplier: [],
        });
        dispatch(fetchChainById(decryptId(params.id)));
        setOpenSelectSupplier(false);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    let term = e.target.value;
    dispatch(fetchSupplierWithSearch({ term }));
  };

  const handleContinue = () => {
    handleCreateSupplyChain();
  };

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <>
      <Dialog maxWidth={'md'} open={openSelectSupplier} onClose={closeSelectSupplier}>
        <DialogContent sx={{ bgcolor: '#fff', width: '800px', position: 'relative', py: 0, pb: 2 }}>
          <DialogTitle sx={{ color: '#0e1090', fontWeight: 'bold', pl: 0 }}>{t('select_the_supplier')}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={closeSelectSupplier}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: 'black',
            }}
          >
            <CloseIcon />
          </IconButton>
          <Grid container spacing={2}>
            <Grid mt={2} item xs={12}>
              <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                  <TextField
                    size="small"
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        borderRadius: '20px',
                      },
                    }}
                    variant="outlined"
                    placeholder={t('search')}
                    value={searchTerm}
                    onChange={handleSearchChange}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton>
                            <SearchIcon />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  <FilterSupplier page={page} itemsPerPage={itemsPerPage} />
                </Box>
                <Box>
                  <Pagination
                    sx={{
                      '& .MuiPaginationItem-root': {
                        color: 'gray',
                      },
                      '& .MuiPaginationItem-root.Mui-selected': {
                        backgroundColor: '#EC895A',
                        color: '#fff',
                      },
                    }}
                    count={pageCount}
                    page={page}
                    onChange={handleChange}
                  />
                </Box>
              </Box>
              <Grid mt={2} container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <Button
                    onClick={() => navigate(`${PATH.ADDSUPPLIER}/supplierId/1`)}
                    sx={{
                      width: '100%',
                      height: '270px',
                      bgcolor: '#0e1090',
                      textTransform: 'capitalize',
                      textAlign: 'center',
                      display: 'flex',
                      flexDirection: 'column',
                      ':hover': { bgcolor: '#0e1090' },
                      color: '#fff',
                    }}
                  >
                    <Typography>
                      <AddIcon sx={{ fontSize: '40px' }} />
                    </Typography>
                    <Typography> {t('add_suppliers')}</Typography>
                  </Button>
                </Grid>
                {currentPageData?.map((supplier) => (
                  <Grid item xs={12} sm={6} md={4} lg={3} key={supplier.id}>
                    <Card sx={{ boxShadow: 'none' }}>
                      <CardContent sx={{ textAlign: 'center', height: '270px', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                        <img
                          // onClick={() => navigate(`${PATH.ADDSUPPLIER}/${encryptId(supplier?.id)}`)}
                          src={supplier?.urls ? supplier?.urls[0]?.url : user}
                          alt=""
                          style={{ width: 50, height: 50, borderRadius: '30px', border: '1px solid gray', cursor: 'pointer' }}
                        />
                        <Checkbox
                          sx={{ position: 'absolute', top: '0px', right: '0px' }}
                          {...label}
                          icon={<CircleOutlinedIcon />}
                          checkedIcon={<CircleIcon />}
                          checked={supplyChainData.supplier.some((s) => s.supplierId === supplier.id)}
                          onChange={() => toggleSupplierSelection(supplier)}
                        />
                        <Typography variant="body2" sx={{ color: '#000', fontWeight: 600 }}>
                          {supplier?.name}
                        </Typography>
                        <Typography sx={{ color: '#0e1090', fontWeight: 500, fontSize: '14px' }}>{supplier?.vanityName?.value}</Typography>
                        <Typography sx={{ color: '#000', fontSize: '12px', fontWeight: 700 }}>
                          <span style={{ color: 'gray' }}>{t('vat')}</span> {supplier?.vat}
                        </Typography>
                        <Typography sx={{ color: 'gray', fontSize: '12px' }}>
                          <LocationOnIcon sx={{ fontSize: '16px', color: '#000' }} />
                          {supplier?.address?.street} {supplier?.address?.city} {supplier?.address?.state} {supplier?.address?.country}
                        </Typography>
                        <Box sx={{ display: 'flex', gap: '2px', justifyContent: 'center' }}>
                          <Chip color="default" label={'Cotton material'} sx={{ fontSize: '10px', height: '20px' }} />
                          <Chip label={`${supplier?.numberOfProducts} Product`} sx={{ fontSize: '10px', height: '20px', bgcolor: 'gray', color: '#fff' }} />
                        </Box>
                        <Button
                          onClick={() => navigate(`${PATH.ADDSUPPLIER}/${encryptId(supplier?.id)}/${page}/${PATH.ADDSUPPLIERPRODUCT}/:productId`)}
                          fullWidth
                          endIcon={<AddIcon sx={{ fontSize: '16px' }} />}
                          size="small"
                          sx={{
                            color: '#fff',
                            fontSize: '12px',
                            bgcolor: '#0e1090',
                            textTransform: 'capitalize',
                            ':hover': { bgcolor: '#0e1090', borderRadius: '30px' },
                            mt: '5px',
                            borderRadius: '30px',
                            px: 1,
                          }}
                        >
                          {t('add_products')}
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        </DialogContent>
        <Grid container bgcolor={'#101010'} py={2} px={1}>
          <Grid item xs={12} textAlign={'center'}>
            <Button onClick={handleContinue} size="small" sx={{ color: '#000', bgcolor: '#fff', borderRadius: '30px', px: 4, ':hover': { bgcolor: '#fff' } }}>
              {t('continue')}
            </Button>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
}
