import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardContent, Chip, Grid, IconButton, InputAdornment, Menu, MenuItem, Pagination, Snackbar, TextField, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import InfoRoundedIcon from '@mui/icons-material/InfoRounded';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SupplierIcon from '../../images/icons/supplierIcon';
import CubeIcon from '../../images/icons/cubeIcon';
import CreateSupplyChain from './createSupplyChain';
import user from '../../images/user.png';
import { RestUrlsConstants } from '../../constants/rest-urls.constants';
import httpService from '../../service/http.service';
import AlertDialog from '../utils/AlertDialog';
import { useNavigate } from 'react-router-dom';
import PATH from '../utils/path';
import { encryptId } from '../utils/encryptDecrypt';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSupplier, fetchSupplierWithSearch } from '../../features/supplierSlice';
import { fetchSupplyChainData, fetchSupplyChainDataWithSearch } from '../../features/chainSlice';
import FilterSupplier from '../utils/FilterSupplier';
import FilterSupplyChain from '../utils/FilterSupplyChain';
import Loader from '../utils/loader';
import UploadCSVFileDialog from './UploadCSVFileDialog';

const SupplierMap = (props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const { supplier, pageCount, page, setPage, itemsPerPage, supplierLoading } = props;
  const [searchTerm, setSearchTerm] = useState('');
  const [searchSupplyChain, setSearchSupplyChain] = useState('');
  const [openCreateSupplyChainForm, setOpenCreateSupplyChainForm] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const [snackBar, setSnackBar] = useState({ open: false, message: '' });
  const [openDialog, setOpenDialog] = useState(false);
  const [anchorSupplyEl, setAnchorSupplyEl] = React.useState(null);
  const openSupply = Boolean(anchorSupplyEl);
  const [updateSupplyChain, setUpdateSupplyChain] = useState(false);
  const [openAlertChainDialog, setOpenAlertChainDialog] = useState(false);
  const [errors, setErrors] = useState({ name: '', description: '' });
  const [lang, setLang] = useState('en');
  const { chainData, loading } = useSelector((state) => state.chain);
  const [chainId, setChainId] = useState(null);
  const [supplierId, setSupplierId] = useState(null);
  const [openUploadCSVSupplier, setOpenUploadCSVSupplier] = useState(false);
  const [supplyChainData, setSupplyChainData] = useState({
    name: '',
    description: '',
    brandProduct: [],
  });
  const dispatch = useDispatch();
  
  const currentPageData = supplier?.suppliers?.slice((page - 1) * itemsPerPage, page * itemsPerPage);

  useEffect(() => {
    dispatch(fetchSupplyChainData(currentPage));
  }, [dispatch, currentPage]);
  const handleSearchChange = (e) => {
    let term = e.target.value;
    setSearchTerm(e.target.value);
    dispatch(fetchSupplierWithSearch({ term }));
  };
  const handleChange = (event, value) => {
    setPage(value);
  };
  const totalPages = Math.ceil(chainData?.totalRecords / 10);
  const handleChainPageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handleSupplyChainSearch = (event) => {
    setSearchSupplyChain(event.target.value);
    let term = event.target.value;
    if (term.trim() === '') {
      dispatch(fetchSupplyChainData(currentPage));
    } else {
      dispatch(fetchSupplyChainDataWithSearch(term));
    }
  };
  const handleDuplicateSupplier = async (id) => {
    try {
      const response = await httpService.post(
        `${RestUrlsConstants.supplier}/${id}/duplicate`,
        {},
        {
          headers: {
            Authorization: localStorage.getItem('Authorization'),
          },
        }
      );
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        dispatch(fetchSupplier({ page, itemsPerPage }));
        setSupplierId(null);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const handleClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSupplierId(id);
  };
  const handleClose = (option) => {
    if (option === t('edit')) {
      navigate(`${PATH.ADDSUPPLIER}/${encryptId(supplierId)}/${page}`);
    } else if (option === t('delete')) {
      setOpenDialog(true);
    } else if (option === t('duplicate')) {
      handleDuplicateSupplier(supplierId);
    }
    setAnchorEl(null);
  };

  const handleDeleteSupplier = async () => {
    try {
      const response = await httpService.delete(`${RestUrlsConstants.supplier}/${supplierId}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        dispatch(fetchSupplier({ page, itemsPerPage }));
        setSupplierId(null);
        setOpenDialog(false);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSupplyActions = (event) => {
    setAnchorSupplyEl(event.currentTarget);
  };
  const handleUpdateSupplyChain = (id) => {
    setChainId(id);
    setUpdateSupplyChain(true);
    setOpenCreateSupplyChainForm(true);
  };
  useEffect(() => {
    if (chainId && updateSupplyChain) {
      const fetchInitialProductData = async () => {
        if (chainId) {
          try {
            const response = await httpService.get(`${RestUrlsConstants.supplyChain}/${chainId}`, {
              headers: { Authorization: localStorage.getItem('Authorization') },
            });
            if (response?.data?.status) {
              setSupplyChainData((prevData) => ({
                ...prevData,
                name: response?.data?.data?.name,
                brandProduct: response?.data?.data?.products,
              }));
            }
          } catch (error) {
            console.error('Error fetching product:', error);
          }
        }
      };
      fetchInitialProductData();
    }
  }, [chainId, updateSupplyChain]);
  useEffect(() => {
    if (chainId && updateSupplyChain) {
      const fetchInitialProductData = async () => {
        if (chainId) {
          try {
            const response = await httpService.get(`${RestUrlsConstants.supplyChain}/${chainId}`, {
              headers: { Authorization: localStorage.getItem('Authorization') },
            });
            if (response?.data?.status) {
              setSupplyChainData((prevData) => ({
                ...prevData,
                description: response?.data?.data?.description?.[lang] || response?.data?.description?.value,
              }));
            }
          } catch (error) {
            console.error('Error fetching product:', error);
          }
        }
      };
      fetchInitialProductData();
    }
  }, [chainId, updateSupplyChain, chainData, lang]);
  const handleCloseAction = (option) => {
    if (option === t('edit')) {
      setUpdateSupplyChain(true);
      setOpenCreateSupplyChainForm(true);
    } else if (option === t('delete')) {
      setOpenAlertChainDialog(true);
    }
    setAnchorSupplyEl(null);
  };
  const handleDeleteSupplyChain = async () => {
    try {
      const response = await httpService.delete(`${RestUrlsConstants.supplyChain}/${chainId}`, {
        headers: {
          Authorization: localStorage.getItem('Authorization'),
        },
      });
      if (response?.data?.status) {
        setSnackBar({ open: true, message: response?.data?.message });
        dispatch(fetchSupplyChainData(currentPage));
        setChainId(null);
        setOpenAlertChainDialog(false);
      }
    } catch (error) {
      console.log(error?.response?.data?.message);
      setSnackBar({ open: true, message: error?.response?.data?.message });
    }
  };

  const handleOpenCreateSupplyChain = () => {
    setOpenCreateSupplyChainForm(true);
  };
  const handleCloseCreateSupplyChain = () => {
    setOpenCreateSupplyChainForm(false);
    setUpdateSupplyChain(false);
    setSupplyChainData({
      name: '',
      description: '',
      brandProduct: [],
    });
  };
  const validateForm = () => {
    let valid = true;
    const newErrors = { name: '',};
    if (!supplyChainData?.name?.trim()) {
      newErrors.name = t('name_is_required');
      valid = false;
    }
    setErrors(newErrors);
    return valid;
  };
  const handleCreateSupplyChain = async () => {
    if (!validateForm()) return;
    if (updateSupplyChain) {
      try {
        const payload = {
          name: supplyChainData?.name,
          description: supplyChainData?.description,
          lang: lang,
          products: supplyChainData?.brandProduct,
        };
        const response = await httpService.put(`${RestUrlsConstants.supplyChain}/${chainId}`, payload, {
          headers: {
            Authorization: localStorage.getItem('Authorization'),
            'Content-Type': 'application/json',
          },
        });
        if (response?.data?.status) {
          navigate(`${PATH.SUPPLYCHAINGRAPH}/${encryptId(chainId)}/${currentPage}`);
          setOpenCreateSupplyChainForm(false);
          setUpdateSupplyChain(false);
          setSupplyChainData({
            name: '',
            description: '',
            brandProduct: [],
          });
          dispatch(fetchSupplyChainData(currentPage));
          setSnackBar({ open: true, message: response?.data?.message });
        }
      } catch (error) {
        console.log(error);
        setSnackBar({ open: true, message: error?.response?.data?.message });
      }
    } else {
      try {
        const payload = {
          name: supplyChainData?.name,
          description: supplyChainData?.description,
          lang: lang,
          products: supplyChainData?.brandProduct,
        };
        const response = await httpService.post(`${RestUrlsConstants.supplyChain}`, payload, {
          headers: {
            Authorization: localStorage.getItem('Authorization'),
            'Content-Type': 'application/json',
          },
        });
        if (response?.data?.status) {
          setOpenCreateSupplyChainForm(false);
          navigate(`${PATH.SUPPLYCHAINGRAPH}/${encryptId(response?.data?.data?.id)}/${currentPage}`);
          setSupplyChainData({
            name: '',
            description: '',
            brandProduct: [],
          });
          dispatch(fetchSupplyChainData(currentPage));
          setSnackBar({ open: true, message: response?.data?.message });
        }
      } catch (error) {
        console.log(error);
        setSnackBar({ open: true, message: error?.response?.data?.message });
      }
    }
  };

  const handleSupplyChainGraph = (id) => {
    navigate(`${PATH.SUPPLYCHAINGRAPH}/${encryptId(id)}/${currentPage}`);
  };
  const snackbarClose = () => {
    setSnackBar({ open: false, message: '' });
  };
  const options = [t('edit'), t('delete'), t('duplicate')];
  const chainOption = [t('edit'), t('delete')];
  return (
    <Box sx={{ height: '84vh' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={6} lg={8} height={'84vh'} className="example" sx={{ overflowY: 'auto' }}>
          <Card sx={{ boxShadow: 'none', p: 0 }}>
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <SupplierIcon />
                <Typography sx={{ color: '#EC895A', fontWeight: 'bold' }}>
                  <span style={{ color: '#000', fontWeight: 'bold', marginRight: '10px' }}>{supplier?.totalRecords}</span>
                  {supplier?.totalRecords <= 1 ? t('supplier') : t('suppliers')}
                </Typography>
              </Box>
              <Box>
                <Button
                  startIcon={<UploadFileIcon sx={{ fontSize: '20px' }} />}
                  sx={{ bgcolor: '#101010', color: '#fff', ':hover': { bgcolor: '#101010' }, borderRadius: 6, textTransform: 'capitalize', px: 2 }}
                  onClick={() => {
                    setOpenUploadCSVSupplier(true);
                  }}
                >
                  {t('upload_csv')}
                </Button>
                <InfoRoundedIcon sx={{ fontSize: '20px', color: 'gray', ml: 1 }} />
              </Box>
            </CardContent>
          </Card>
          <Grid mt={2} item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <TextField
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '20px',
                    },
                  }}
                  variant="outlined"
                  placeholder={t('search')}
                  value={searchTerm}
                  onChange={handleSearchChange}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FilterSupplier page={page} itemsPerPage={itemsPerPage} />
              </Box>
              <Box>
                {supplier?.totalRecords > 0 && (
                  <Pagination
                    sx={{
                      '& .MuiPaginationItem-root': {
                        color: 'gray',
                      },
                      '& .MuiPaginationItem-root.Mui-selected': {
                        backgroundColor: '#EC895A',
                        color: '#fff',
                      },
                    }}
                    count={pageCount}
                    page={page}
                    onChange={handleChange}
                  />
                )}
              </Box>
            </Box>
          </Grid>
          <Grid mt={2} container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={3}>
              <Button
                onClick={() => navigate(`${PATH.ADDSUPPLIER}/supplierId/1`)}
                sx={{
                  width: '100%',
                  height: '270px',
                  bgcolor: '#0e1090',
                  textTransform: 'capitalize',
                  textAlign: 'center',
                  display: 'flex',
                  flexDirection: 'column',
                  ':hover': { bgcolor: '#0e1090' },
                  color: '#fff',
                }}
              >
                <Typography>
                  <AddIcon sx={{ fontSize: '40px' }} />
                </Typography>
                <Typography> {t('add_suppliers')}</Typography>
              </Button>
            </Grid>
            {supplierLoading ? (
              <Loader />
            ) : (
              <>
                {currentPageData?.map((supplier) => (
                  <Grid item xs={12} sm={12} md={6} lg={3} key={supplier?.id}>
                    <Card sx={{ boxShadow: 'none' }}>
                      <CardContent sx={{ textAlign: 'center', height: '270px', position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
                        <img
                          onClick={() => navigate(`${PATH.ADDSUPPLIER}/${encryptId(supplier?.id)}/${page}`)}
                          src={supplier?.urls ? supplier?.urls[0]?.url : user}
                          alt=""
                          style={{ width: 50, height: 50, borderRadius: '30px', border: '1px solid gray', cursor: 'pointer' }}
                        />
                        <IconButton
                          sx={{ position: 'absolute', top: '10px', right: '5px', color: '#000' }}
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? 'long-menu' : undefined}
                          aria-expanded={open ? 'true' : undefined}
                          aria-haspopup="true"
                          onClick={(e) => handleClick(e, supplier?.id)}
                        >
                          <MoreVertIcon />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          onClose={() => handleClose(null)}
                          sx={{
                            '& .MuiPaper-root': {
                              boxShadow: '0px 0px 1px 1px #cccccc',
                            },
                          }}
                        >
                          {options.map((option) => (
                            <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleClose(option)}>
                              {option}
                            </MenuItem>
                          ))}
                        </Menu>
                        <Tooltip title={supplier?.name} placement="top">
                          <Typography variant="body2" sx={{ color: '#000', fontWeight: 600, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}>
                            {supplier?.name}
                          </Typography>
                        </Tooltip>
                        <Tooltip title={supplier?.vanityName?.value} placement="top">
                          <Typography sx={{ color: '#0e1090', fontWeight: 500, fontSize: '14px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', width: '100%' }}>
                            {supplier?.vanityName?.value}
                          </Typography>
                        </Tooltip>
                        <Tooltip title={supplier?.vat} placement="top">
                          <Typography sx={{ color: '#000', fontSize: '12px', fontWeight: 700, width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <span style={{ color: 'gray' }}>{t('vat_code')}:</span> {supplier?.vat}
                          </Typography>
                        </Tooltip>
                        <Tooltip title={`${supplier?.address?.street} ${supplier?.address?.city} ${supplier?.address?.state} ${supplier?.address?.country}`} placement="top">
                          <Typography sx={{ color: 'gray', fontSize: '12px', width: '100%', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            <LocationOnIcon sx={{ fontSize: '16px', color: '#000' }} />
                            {supplier?.address?.street} {supplier?.address?.city} {supplier?.address?.state} {supplier?.address?.country}
                          </Typography>
                        </Tooltip>
                        <Box sx={{ display: 'flex', gap: '2px', justifyContent: 'center' }}>
                          <Chip label={`${supplier?.numberOfProducts} products`} sx={{ fontSize: '10px', height: '20px', bgcolor: 'gray', color: '#fff' }} />
                        </Box>
                        <Button
                          fullWidth
                          endIcon={<AddIcon sx={{ fontSize: '16px' }} />}
                          size="small"
                          sx={{
                            color: '#fff',
                            fontSize: '12px',
                            bgcolor: '#0e1090',
                            textTransform: 'capitalize',
                            ':hover': { bgcolor: '#0e1090', borderRadius: '30px' },
                            mt: '5px',
                            borderRadius: '30px',
                            px: 1,
                          }}
                          // navigate(`${PATH.ADDSUPPLIERPRODUCT}/:productId`);
                          onClick={() => {
                            navigate(`${PATH.ADDSUPPLIER}/${encryptId(supplier?.id)}/${page}/${PATH.ADDSUPPLIERPRODUCT}/:productId`);
                          }}
                        >
                          {t('add_proudcts')}
                        </Button>
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={4} height={'84vh'} className="example" sx={{ overflowY: 'auto' }}>
          <Card sx={{ boxShadow: 'none', p: 0 }}>
            <CardContent sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex' }}>
                <CubeIcon />
                <Typography sx={{ color: '#EC895A', fontWeight: 'bold' }}>
                  {' '}
                  <span style={{ color: '#000', fontWeight: 'bold', marginRight: '10px', marginLeft: '5px' }}>{chainData?.totalRecords}</span>
                  {t('supply_chain')}
                </Typography>
              </Box>
              <Box>
                <Button
                  startIcon={<UploadFileIcon sx={{ fontSize: '20px' }} />}
                  sx={{ bgcolor: '#101010', color: '#fff', ':hover': { bgcolor: '#101010' }, borderRadius: 6, textTransform: 'capitalize', px: 2 }}
                  onClick={() => {
                    setOpenUploadCSVSupplier(true);
                  }}
                >
                  {t('upload_csv')}
                </Button>
                <InfoRoundedIcon sx={{ fontSize: '20px', color: 'gray' }} />
              </Box>
            </CardContent>
          </Card>
          <Grid container spacing={2}>
            <Grid mt={2} item xs={12}>
              <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                <TextField
                  size="small"
                  sx={{
                    '& .MuiOutlinedInput-root': {
                      borderRadius: '20px',
                    },
                  }}
                  variant="outlined"
                  placeholder={t('search')}
                  value={searchSupplyChain}
                  onChange={handleSupplyChainSearch}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <FilterSupplyChain currentPage={currentPage} />
              </Box>
              <Grid container spacing={2} mt={2}>
                <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'end' }}>
                  {chainData?.totalRecords > 0 && (
                    <Pagination
                      sx={{
                        '& .MuiPaginationItem-root': {
                          color: 'gray',
                        },
                        '& .MuiPaginationItem-root.Mui-selected': {
                          backgroundColor: '#EC895A',
                          color: '#fff',
                        },
                      }}
                      count={totalPages}
                      page={currentPage}
                      onChange={handleChainPageChange}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Button
                    sx={{
                      width: '100%',
                      bgcolor: '#0e1090',
                      textTransform: 'capitalize',
                      display: 'flex',
                      textAlign: 'justify',
                      justifyContent: 'space-between',
                      ':hover': { bgcolor: '#0e1090' },
                      color: '#fff',
                    }}
                    onClick={() => handleOpenCreateSupplyChain()}
                  >
                    <Box>
                      <Typography> {t('create_supply_chain')}</Typography>
                      <Typography sx={{ fontSize: '12px' }}>{t('suppliers_have_products_or_services')}</Typography>
                    </Box>
                    <Typography>
                      <AddIcon sx={{ fontSize: '40px' }} />
                    </Typography>
                  </Button>
                </Grid>
                {loading ? (
                  <Grid item xs={12}>
                    <Loader />
                  </Grid>
                ) : (
                  <>
                    {chainData?.supplyChains?.map((val, index) => (
                      <Grid key={index} item xs={12}>
                        <Card sx={{ boxShadow: 'none' }}>
                          <CardContent sx={{ py: 1 }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                              <Typography onClick={() => handleSupplyChainGraph(val?.id)} sx={{ color: '#0e1090', fontWeight: 600, cursor: 'pointer' }}>
                                {val?.name}
                              </Typography>
                              <IconButton
                                sx={{ color: '#000' }}
                                aria-label="more"
                                id="long-button"
                                aria-controls={openSupply ? 'long-menu' : undefined}
                                aria-expanded={openSupply ? 'true' : undefined}
                                aria-haspopup="true"
                                onClick={(e) => {
                                  handleSupplyActions(e);
                                  setChainId(val?.id);
                                }}
                              >
                                <MoreVertIcon />
                              </IconButton>
                              <Menu
                                sx={{
                                  '& .MuiPaper-root': {
                                    boxShadow: '0px 0px 1px 1px #cccccc',
                                  },
                                }}
                                anchorEl={anchorSupplyEl}
                                open={openSupply}
                                onClose={() => {
                                  handleCloseAction(null);
                                }}
                              >
                                {chainOption.map((option) => (
                                  <MenuItem key={option} selected={option === 'Pyxis'} onClick={() => handleCloseAction(option)}>
                                    {option}
                                  </MenuItem>
                                ))}
                              </Menu>
                            </Box>
                            <Typography mt={2} sx={{ color: '#0e1090', fontWeight: 600, fontSize: '14px' }}>
                              {t('Products')} <span style={{ color: '#EC895A' }}>{`(${val?.products?.length})`}</span>
                            </Typography>
                            <Grid container spacing={1}>
                              <Grid className="example" item xs={10} sx={{ display: 'flex', gap: '5px', flexWrap: 'nowrap', overflow: 'hidden', alignItems: 'center' }}>
                                {val?.products?.map((item) => (
                                  <Chip color="default" label={item?.name} sx={{ fontSize: '10px', height: '20px', textTransform: 'capitalize', fontWeight: '600' }} />
                                ))}
                              </Grid>
                              <Grid item xs={2} sx={{ display: 'flex', alignItems: 'center' }}>
                                <IconButton
                                  onClick={() => {
                                    handleUpdateSupplyChain(val?.id);
                                  }}
                                  size="small"
                                  sx={{ bgcolor: '#0e1090', color: '#fff', borderRadius: '5px', ':hover': { bgcolor: '#0e1090' } }}
                                >
                                  <ArrowForwardIosIcon />
                                </IconButton>
                              </Grid>
                            </Grid>
                          </CardContent>
                        </Card>
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <UploadCSVFileDialog setOpenUploadCSVSupplier={setOpenUploadCSVSupplier} openUploadCSVSupplier={openUploadCSVSupplier} />
      <CreateSupplyChain
        handleCloseCreateSupplyChain={handleCloseCreateSupplyChain}
        openCreateSupplyChainForm={openCreateSupplyChainForm}
        updateSupplyChain={updateSupplyChain}
        handleCreateSupplyChain={handleCreateSupplyChain}
        supplyChainData={supplyChainData}
        setSupplyChainData={setSupplyChainData}
        errors={errors}
        lang={lang}
        setLang={setLang}
      />
      <Snackbar
        open={snackBar.open}
        autoHideDuration={2000}
        message={snackBar.message}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={snackbarClose}
        className="snackBarColor"
        key="snackbar"
      />
      <AlertDialog
        btnName={t('delete')}
        title={t('delete_supplier')}
        description={t('are_you_sure_you_want_to_delete_this_supplier')}
        open={openDialog}
        handleClick={handleDeleteSupplier}
        handleCloseDialog={handleCloseDialog}
      />
      <AlertDialog
        btnName={t('delete')}
        title={t('delete_supply_Chain')}
        description={t('are_you_sure_you_want_to_delete_this_supply_chain')}
        open={openAlertChainDialog}
        handleClick={handleDeleteSupplyChain}
        handleCloseDialog={() => {
          setOpenAlertChainDialog(false);
          setChainId(null);
        }}
      />
    </Box>
  );
};

export default SupplierMap;
